<template>
  <modal class="alertsPopup" name="InfoPopup" transition="pop-out" :adaptive="true" @before-open="beforeOpen" @opened="moveToPopup">
    <div class="thanksPopup fancybox-content" id="noFlightsFoundPopup">
      <div class="thanksPopup__decor"></div>
      <div class="thanksPopup__inner">
        <div class="thanksPopup__title">{{title}}</div>
        <div class="thanksPopup__text">{{text}}</div>
        <button class="thanksPopup__button thanksPopup__button--close" @click="() => {$modal.hide('InfoPopup'); this.$emit('success')}">
          <span class="text">Ok</span>
        </button>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('InfoPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './AlertsPopup.styl'

  export default {
    name: 'InfoPopup',
    data: () => ({
      title: null,
      text: null,
    }),
    methods: {
      moveToPopup () {
        if (document.querySelector('.d-mobile')) {
          document.querySelector('.thanksPopup').scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
        }
      },
      beforeOpen (event) {
        this.title = event.params.title
        this.text = event.params.text
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
